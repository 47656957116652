<template>
  <el-container class="iot-home-container">
    <el-aside width="35%" class="iot-aside">
      <div class="iot-left-item public-shadow">
        <div class="iot-item-head">
          <h4 class="iot-item-title">{{indexConfig.title1}}</h4>
          <!-- <span class="iot-item-span">更新于：2021-09-02 10:22:22</span> -->
        </div>
        <div class="iot-item-body">
          <div id="highcharts" ref="highcharts"></div>
          <div class="iot-data-group">
             <div class="iot-data-group-item" @click="jump(0)">
              <div class="iot-countArr"></div>
              <div>总计{{ countArr.count }}台</div>
            </div>
            <div class="iot-data-group-item" @click="jump(1)">
              <div class="iot-online"></div>
              <div>在线{{ countArr.onLine }}台</div>
            </div>
            <div class="iot-data-group-item" @click="jump(2)">
              <div class="iot-offline"></div>
              <div>离线{{ countArr.offLine }}台</div>
            </div>
            <!-- <el-button class="iot-data" type="default"
                ><i class="iconfont iot-point">&#xe605;</i>在线设备
                <div class="iot-count">{{ countArr.onLine }}台</div>
              </el-button> -->
            <!-- <el-button class="iot-data" type="default"
                ><i class="iconfont iot-grey-point">&#xe605;</i>离线设备
                <div class="iot-count">{{ countArr.offLine }}台</div>
              </el-button> -->
          </div>
        </div>
      </div>
      <div class="iot-left-item public-shadow">
        <div class="iot-item-head">
          <h4 class="iot-item-title">{{indexConfig.title3}}</h4>
          <!-- <span class="iot-item-span">更新于：2021-09-02 10:22:22</span> -->
        </div>
        <div class="iot-item-body">
          <div id="highcharts1" ref="highcharts1"></div>
          <div class="iot-data-group">
            <div class="iot-data-group-item" @click="jump(5)">
              <div class="iot-normal"></div>
              <div>正常{{ countArr.normalCount }}台</div>
            </div>
            <div class="iot-data-group-item" @click="jump(3)">
              <div class="iot-warning"></div>
              <div>报警{{ countArr.alarmCount }}台</div>
            </div>
            <div class="iot-data-group-item" @click="jump(4)">
              <div class="iot-fault"></div>
              <div>故障{{ countArr.faultCount }}台</div>
            </div>

            <!-- <el-button-group>
              <el-button class="iot-data" type="default"
                ><i class="iconfont iot-warning-point">&#xe605;</i>报警设备
                <div class="iot-count">{{ countArr.alarmCount }}台</div>
              </el-button>
              <el-button class="iot-data" type="default"
                ><i class="iconfont iot-normal-point">&#xe605;</i>正常设备
                <div class="iot-count">{{ countArr.normalCount }}台</div>
              </el-button>
            </el-button-group> -->
          </div>
        </div>
      </div>
      <div class="iot-left-item public-shadow">
        <div class="iot-item-head">
          <h4 class="iot-item-title" style="float: left">{{indexConfig.title4}}</h4>
          <el-row>
            <el-col class="iot-col" :span="6"
              ><div class="grid-content bg-purple-light">
                今日报警数： {{ warningCountArr.todayNum }}
              </div></el-col
            >
            <el-col class="iot-col" :span="6"
              ><div class="grid-content bg-purple">
                昨日报警数： {{ warningCountArr.yesterdayNum }}
              </div></el-col
            >
            <el-col class="iot-col" :span="6"
              ><div class="grid-content bg-purple-light">
                报警总数： {{ warningCountArr.alarmNum }}
              </div></el-col
            >
          </el-row>
        </div>
        <div class="iot-item-body">
          <el-form :inline="true" :model="statisticsForm" label-width="70px">
            <el-form-item label="项目组" style="width: 16%;">
              <el-select
              style="width: 100px;"
              v-el-select-loadmore="loadmore"
                v-model="statisticsForm.projectGroupId"
                placeholder="项目组名称"
                size="mini"
                :filter-method="filterLoginIdList"
                @clear="clearList"
                clearable 
                filterable
                popper-class="eloption"
                    :popper-append-to-body="true"

              >
                <el-option
                  v-for="item in projectGroupArr200"
                  :key="item.projectGroupId"
                  :label="item.projectGroupName"
                  :value="item.projectGroupId"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="方案"  style="width: 16%;">
              <el-select
              style="width: 100px;"
                v-model="statisticsForm.planId"
                placeholder="方案名称"
                size="mini"
                popper-class="eloption"
                    :popper-append-to-body="true"

              >
                <el-option
                  v-for="item in caseArr"
                  :key="item.planId"
                  :label="item.planName"
                  :value="item.planId"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="时间范围"  style="width: 30%;">
              <el-radio v-model="statisticsForm.radio" :label="0">日</el-radio>
              <el-radio v-model="statisticsForm.radio" :label="1">周</el-radio>
              <el-radio v-model="statisticsForm.radio" :label="2">月</el-radio>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="mini" @click="onSearch"
                >查询</el-button
              >
            </el-form-item>
          </el-form>
          <div
            :style="`width: 598px; height:120px;zoom:${zoom};transform:scale(${1/zoom});transform-origin:0 0`"
            ref="columRef"
          ></div>
        </div>
      </div>
    </el-aside>
    <el-main class="iot-main">
      <div class="iot-right-item public-shadow">
        <div class="iot-item-head">
          <h4 class="iot-item-title" style="float: left">{{indexConfig.title2}}</h4>
          <!--<el-form
            :inline="true"
            :model="recordForm"
            class="iot-head-form"
            label-width="120px"
          >
             <el-form-item label="时间区间">
              <el-date-picker
                size="mini"
                v-model="recordForm.timeInterval"
                type="daterange"
                align="right"
                unlink-panels
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :picker-options="pickerOptions"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="项目组名称">
              <el-select
                v-model="recordForm.projectGroupId"
                placeholder="项目组名称"
                size="mini"
              >
                <el-option
                  v-for="item in projectGroupArr"
                  :key="item.projectGroupId"
                  :label="item.projectGroupName"
                  :value="item.projectGroupId"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="mini" @click="onRecordSearch"
                >查询</el-button
              >
            </el-form-item>
          </el-form> -->
        </div>
        <div class="iot-item-body" >
          <div  class="scollBox">
            <el-table
            class="iot-table"
            :class="{anmation:warningList.length>0}"
            :data="warningList"
            style="width: 100%"
            size="mini"
          >
            <el-table-column prop="alarmType" label="状态" width="100">
              <template slot-scope="{row}">
                <el-tag type="danger"  v-if="row.alarmType==2">报警</el-tag>
                <el-tag type="info" v-else>报警解除</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="projectName" :label="alarmConfig.title1">
            </el-table-column>
            <el-table-column prop="projectGroupName" :label="alarmConfig.title2">
            </el-table-column>
            <el-table-column prop="startTime" :label="alarmConfig.title3">
            </el-table-column>
            <el-table-column prop="alarmInfo" :label="alarmConfig.title7" width="300">
            </el-table-column>
          </el-table>
          </div>
        </div>
      </div>
      <div class="iot-right-item public-shadow iot_newMap">
        <div class="iot-item-head">
          <h4 class="iot-item-title">{{indexConfig.title6}}</h4>
        </div>
        <div class="iot-item-body ">
          <el-amap
            vid="amap"
            :amap-manager="amapManager"
            :zoom="zoom"
            :center="center"
            v-if="isGetOver"
          >
            <el-amap-marker
              v-for="(marker, index) in markers"
              :key="index"
              :position="marker.position"
              :events="marker.events"
              :plugin="plugin"
            >
              <template>
                <img
                  class="iot-marker-img"
                  src="https://lz-filedb.oss-cn-shanghai.aliyuncs.com/iot-system/sys-program/posRed.png"
                />
              </template>
            </el-amap-marker>
          </el-amap>
        </div>
      </div>
    </el-main>
  </el-container>
</template>
<script>
let that;
import moment from "moment";
import AMap from "vue-amap";
let amapManager = new AMap.AMapManager();
export default {
  name: "Home",
  inject:['reload'],
  props: {},
  data() {
    return {
      zoom:1,
      timer:null,
      scrollpage:1,
      projectGroupArr200:[],
      indexConfig:{},
      alarmConfig: {
      title1:"",
      title2:"",
      title3:"",
      title7:"",
      },
      isGetOver: false,
      recordForm: {
        current: 1,
        size: 10,
        alarmType: '',
      },
      warningList: [],
      warningTotal: 0,
      statisticsForm: {
        projectGroupId: "",
        planId: "",
        radio: 2,
        startDate: "",
        endDate: "",
      },
      caseArr: [],
      projectGroupArr: [],
      warningCountArr: {},
      countArr: {},
      zoom: 6,
      center: [116.397972, 39.906901],
      amapManager,
      plugin: [
        {
          pName: "Geolocation",
          events: {
            init(o) {
              o.getCityInfo((status, result) => {
                status;
                if (result) {
                  that.center = result.center;
                  that.$nextTick();
                }
              });
            },
          },
        },
      ],
      events: {
        init(o) {
          setTimeout(() => {
            let cluster = new AMap.MarkerClusterer(o, that.markerRefs, {
              gridSize: 80,
              renderCluserMarker: that._renderCluserMarker,
            });
            cluster;
          }, 1000);
        },
        complete: () => {
          //地图首次加载完成 并完成覆盖物加载 没有参数 只有map有这个事件
        },
        click: (event) => {
          event;
          //事件 event.target是组件实例 可以使用api方法
        },
      },
      markers: [],
      recordData: [],
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      getData(data, colorArr, ref) {
        let option = {
          title: {
            text: "",
          },
          chart: {
            type: "pie",
            options3d: {
              enabled: true,
              alpha: 45,
              beta: 0,
            },
          },
          tooltip: {
            pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
          },
          plotOptions: {
            pie: {
              allowPointSelect: true,
              cursor: "pointer",
              depth: 35,
              dataLabels: {
                enabled: false,
                format: "{point.name}",
              },
              colors: colorArr,
            },
          },
          series: [
            {
              type: "pie",
              name: "项目台数",
              data: data,
            },
          ],
          options3d: {
            enabled: false, // 是否启用 3D 功能，默认是 false，设置为 true 开启 3D 功能
            alpha: 0, // 内旋转角度
            beta: 0, // 外旋转角度
            depth: 100, // 图表的全深比，即为3D图X，Y轴的平面点固定，以图的Z轴原点为起始点上
            // 下旋转，值越大往外旋转幅度越大，值越小往内旋转越大
            viewDistance: 100, // 视图距离，它对于计算角度影响在柱图和散列图非常重要。此值不能用于3D的饼图
            frame: {
              // Frame框架，3D图包含柱的面板，我们以X ,Y，Z的坐标系来理解，X轴与 Z轴所形成
              // 的面为bottom，Y轴与Z轴所形成的面为side，X轴与Y轴所形成的面为back，bottom、
              // side、back的属性一样，其中size为感官理解的厚度，color为面板颜色
              bottom: {
                size: 1,
                color: "transparent",
              },
              side: {
                size: 1,
                color: "transparent",
              },
              back: {
                size: 1,
                color: "transparent",
              },
            },
          },
          credits: {
            enabled: false,
          },
        };
        that.$highcharts.chart(that.$refs[ref], option);
      },
    };
  },
  directives: {
    "el-select-loadmore": {
      bind(el, binding) {
        let SELECT_DOM = el.querySelector(
          ".el-select-dropdown .el-select-dropdown__wrap"
        );
        SELECT_DOM.addEventListener("scroll", function () {
          let condition =
            this.scrollHeight - this.scrollTop <= this.clientHeight;
          if (condition) {
            binding.value();
          }
        })
      }
    }
  },
  methods: {
    loadmore() {
      if (this.timer) {
        clearTimeout(this.timer)
      this.timer=null
      } 
     this.timer= setTimeout(() => {
        that.scrollpage++
        let newprojectGroupArr200 = that.projectGroupArr.slice(that.projectGroupArr200.length - 1, 50 * that.scrollpage)
      that.projectGroupArr200 = [...that.projectGroupArr200,...newprojectGroupArr200]
     },1000)
    },
    //获取告警配置
      getAlarmConfig() {
      this.$api.SetTitleConfig.getAlarmConfig().then((res) => {
        this.alarmConfig = res.data;
      });
    },
     //获取首页配置
    getIndexConfig() {
      this.$api.SetTitleConfig.getIndexConfig().then((res) => {
        this.indexConfig = res.data;
      });
    },
    getColum(ref, xArr, yArr) {
      let len = 10 - xArr.length;

      for (let i = 0; i < len; i++) {
        xArr.push("暂无项目");
        yArr.push(0);
      }

      let chart = {
        chart: {
          type: "column",
        },
        title: {
          text: "",
        },
        
        xAxis: {
          categories: xArr,
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: "",
          },
        },
        tooltip: {
          // head + 每个 point + footer 拼接成完整的 table
          headerFormat:
            '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat:
            '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0"><b>{point.y:.1f} 条</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
        },
        plotOptions: {
          column: {
            borderWidth: 0,
          },
        },
        legend: { enabled: false },
        series: [
          {
            name: "",
            color: "#ff9002",
            data: yArr,
          },
        ],
        credits: {
          enabled: false,
        },
      };

      that.$highcharts.chart(that.$refs[ref], chart);
    },
    onSearch: function () {
      let endTime = new Date();
      let startTime;

      switch (that.statisticsForm.radio) {
        case 0:
          startTime = endTime.getTime();
          break;
        case 1:
          startTime = endTime.getTime() - 3600 * 1000 * 24 * (endTime.getDay()==0?6:endTime.getDay()-1);

          break;
        case 2:
          startTime = endTime.getTime() - 3600 * 1000 * 24 * (endTime.getDate()-1);

          break;
        default:
          break;
      }
      that.$set(
        that.statisticsForm,
        "startDate",
        moment(startTime).format("yyyy-MM-DD")
      );
      that.$set(
        that.statisticsForm,
        "endDate",
        moment(endTime).format("yyyy-MM-DD")
      );

      that.$api.home.getWarningCount(that.statisticsForm).then((response) => {
        if (response.code == 200) {
          let xArr = [];
          let yArr = [];
          response.data.forEach((ele) => {
            xArr.push(ele.projectName || "暂无名称");
            yArr.push(ele.alarmCount);
          });
          that.getColum("columRef", xArr, yArr);
        }
      });
    },
    getWarningList: function () {
      that.$api.home.getWarningPage(that.recordForm).then((response) => {
        if (response.code == 200) {
          that.warningList = response.data.records;
          that.warningTotal = response.data.total;
        }
      });
    },
    getCount: function () {
      that.$api.home.getCount().then((response) => {
        if (response.code == 200) {
          that.countArr = response.data;
          that.getData(
            [
              ["在线", that.countArr.onLine],
              ["离线", that.countArr.offLine],
            ],
            ["#a3ccff", "#d8d8d8"],
            "highcharts"
          );
        }
      });
    },
    getAlarmInfoCount: function () {
      that.$api.home.getAlarmInfoCount().then((response) => {
        if (response.code == 200) {
          that.warningCountArr = response.data;

          that.getData(
            [
              ["报警项目", that.countArr.alarmCount],
              ["正常项目", that.countArr.normalCount],
              ["故障项目", that.countArr.faultCount],
            ],
            ["#F56C6C", "#72dd74", "#e6a23c"],
            "highcharts1"
          );
        }
      });
    },
    // 获取项目组列表
    getProjectGroup: function () {
      that.$api.home.getProjectGroup().then((response) => {
        if (response.code == 200) {
          that.projectGroupArr = response.data;
        }
      });
    },
    getCaseList: function () {
      that.$api.home.getCaseList().then((response) => {
        if (response.code == 200) {
          that.caseArr = response.data;
        }
      });
    },
    getPoint: function () {
      that.$api.home.getPoint().then((response) => {
        if (response.code == 200) {
          that.projectGroupArr = response.data;
          let curEditArr = []
            that.projectGroupArr200 = response.data.slice(0, 50).concat(curEditArr); //取二十条展示       
          let markers = [];
          // 数据去重
          let obj = {};
        let newProjectIds =response.data.reduce(function (item, next) {
      obj[next.longitude] ? '' : obj[next.longitude] = true && obj[next.latitude] ? '' : obj[next.latitude] = true && item.push(next);
      return item;
        }, []);
        newProjectIds.forEach((ele,index) => {
            markers.push({
              position: [ele.longitude, ele.latitude],
            })
            if (ele.longitude > 0 && ele.latitude > 0 && index==0) {
              that.center = [ele.longitude, ele.latitude];
            }
          });
          that.markers = markers;
          that.isGetOver = true;
        }
      });
    },
    filterLoginIdList(query = "") {  //自定义的筛选出所输入值所匹配出的集合
      let arr = that.projectGroupArr.filter((item) => {
        return item.projectGroupName.includes(query);
      });
      if (arr.length > 200) {
        that.projectGroupArr200 = arr.slice(0, 200);
      } else {
        that.projectGroupArr200 = arr;
      }
    },
    clearList() {
     that.projectGroupArr200= that.projectGroupArr.slice(0, 20);
    },
    jump(state){
      this.$router.push(`/projectInformation?projectState=${state}`);
    },
  },
  beforeMount: function () {    
    that = this;
  },
  created() {
    // that.getProjectGroup();
  },
  mounted: function () {
    this.zoom = 1 / document.body.style.zoom;
    window.addEventListener('resize', () => {
      this.zoom = 1 / document.body.style.zoom;
    })
  //   if (this.$store.state.openNumber) {
  //     this.$store.commit('setopenNumber', false)
  //     this.reload()
  //  }
    that.getIndexConfig();
    that.getAlarmConfig();
    that.getCaseList();
    that.getWarningList();
    that.onSearch();
    that.getCount();
    that.getAlarmInfoCount();
    that.getPoint();
  },
  beforeDestroy: function () {
    that.$refs.chinaMap.destroy();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
* {
  box-sizing: border-box;
}
.iot-home-container {
  padding: 10px 30px;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.iot-home-container /deep/.el-button {
  padding: 5px 12px;
  font-size: 10px;
}
.iot-aside,
.iot-main {
  height: calc(969px - 60px);
  overflow: hidden;
}
.iot-main {
  padding: 0 10px;
}
.iot-left-item,
.iot-right-item {
  width: 100%;
  height: 31%;
  margin: 10px 0;
  border-radius: 5px;
}
.iot_newMap{
  height: 63% !important;
}
.iot-item-head {
  height: 40px;
}
.iot-item-title {
  padding: 11px 17px;
  display: inline-block;
  width: auto !important;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: 500;
  font-size: 16px;
}
.iot-item-span {
  float: right;
  padding: 17px;
  font-size: 12px;
}
.iot-item-body {
  width: 100%;
  height: calc(100% - 40px);
  padding: 0;
  position: relative;
}
#highcharts,
#highcharts1 {
  width: 70%;
  height: calc(100% - 10px);
  margin-left: 1%;
  display: inline-block;
  float: left;
}
.iot-data-group {
  display: inline-block;
  width: 29%;
  height: 100%;
  z-index: 10;
  font-size: 12px;
}
.iot-data-group-item {
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  cursor: pointer;
  font-size: 14px;
}
.iot-data-group-item div {
  display: inline-block;
}

.iot-point {
  color: #a3ccff;
}
.iot-grey-point {
  color: #d8d8d8;
}
.iot-warning-point {
  color: #f56c6c;
}
.iot-normal-point {
  color: #72dd74;
}
.iot-form-inline {
  width: 100%;
  text-align: center;
}
.scollBox{
  width: 100%;
  height: 200px;
  overflow: hidden;
}
.iot-table {
  width: 92% !important;
  margin-left: 4%;
}
.anmation{
  animation: myScroll 20s linear infinite;
}
.scollBox:hover .iot-table{
  animation-play-state: paused;
}
@keyframes myScroll {
      from {
        top: 20px;
      }
      to {
        top: -300px;
      }
    }
.iot-head-form {
  padding: 3px;
}
.iot-item-body >>> .grid-content {
  background-color: #f7f7f7;
  line-height: 35px;
  margin-top: 5px;
}
.iot-col {
  line-height: 30px;
  margin-top: 5px;
  text-align: center;
  background-color: #f7f7f7;
  font-size: 12px;
}
.iot-col:nth-child(1),
.iot-col:nth-child(2) {
  border-right: 1px solid #b8b8b8;
}
.highcharts-no-tooltip {
  display: none;
}
.iot-marker-img,
.iot-sign {
  animation: opacity-change 1s ease-in-out infinite;
  -webkit-animation: opacity-change 1s ease-in-out infinite;
  -moz-animation: opacity-change 1s ease-in-out infinite;
  -o-animation: opacity-change 1s ease-in-out infinite;
}
.iot-marker-img {
  width: 35px;
  height: 35px;
}
.iot-sign {
  width: 15px;
  height: 15px;
}
@keyframes opacity-change {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes opacity-change {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-moz-keyframes opacity-change {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-o-keyframes opacity-change {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.iot-count {
  width: 100%;
  text-align: center;
  margin-top: 5px;
}
.iot-online,
.iot-countArr,
.iot-offline,
.iot-warning,
.iot-fault,
.iot-normal {
  width: 30px;
  height: 15px;
  border-radius: 3px;
  display: inline-block;
  margin-right: 5px;
}

.iot-online {
  background-color: #a3ccff;
}
.iot-countArr {
 background-color: rgb(78,115,223);
}
.iot-offline {
  background-color:  #d8d8d8;
}
.iot-warning {
  background-color: #f56c6c;
}
.iot-fault {
  background-color: #e6a23c;
}
.iot-normal {
  background-color: #72dd74;
}
</style>
<style>
.eloption  .el-select-dropdown__wrap{
  height: 200px !important;
}
</style>
